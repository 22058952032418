body {
  a {
    outline: none;
  }

  ul {
    list-style-type: none;
  }

  * {
    outline: none;
  }

  color: $main-color;
  // font-weight: 500;
  font-size: 14px;
  backface-visibility: hidden;
  .close {
    position: absolute;
    top: 1.2rem;
    right: 10%;
    cursor: pointer;
    &:before,
    &:after {
      content: ' ';
      border-top: 0.1rem solid #b9b9b9;
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
      left: 0.925rem;
    }
    &:hover {
      &:before,
      &:after {
        border-color: $gray-dark;
      }
    }
  }
  .loading-bar {
    background-color: $main-blue;
    background: linear-gradient(to right, #30609b, $main-blue, #7cb5f9);
    box-shadow: 0 0 10px rgba(119, 182, 255, 0.7);
    height: 0.2rem;
    position: fixed;
    z-index: 99999;
  }
  .empty-avatar {
    background: $bg-empty-avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1),
      0 0 0 1px rgba(44, 43, 63, 0.13);
    span {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .article__title a {
    color: #3f3f3f;
    &:hover {
      color: $a-hover-color;
    }
  }
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $mask-bg-color;
    z-index: 1;
  }

  .margin-auto {
    margin: auto 0;
  }
}
#main {
  color: $main-color;
  min-height: 50rem;
  background-color: #fff;

  .hide,
  .hidePeerPage {
    display: none;
  }
  .see-more {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.8rem;
    cursor: pointer;
    font-size: 0.8rem;
    color: #aaa;
    transition: 0.3s all ease-in-out;
    text-transform: lowercase;
    border: none;
    &:hover {
      color: #f49649;
      font-weight: 600;
    }
    .icon {
      font-size: 0.6rem;
      position: relative;
      top: 0.5rem;
      left: -0.2rem;
    }
  }
  .hidedItem {
    display: none;
    transition: none;
  }
  .zoom__activated {
    & > .column {
      transition: nth($transitions, 2);
      &:last-child {
        transform: scale(1.2);
      }
      .tmlp__part {
        transform: scale(1.2);
      }
    }
  }
  .centered-img {
    overflow: hidden;
    text-align: center;
    position: relative;

    &::before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .dotted:before {
    content: '';
    border-radius: 2rem;
    background-color: $main-orange;
    top: 22%;
    right: 0;
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    border: 0.15rem solid #fff;
  }
}

// General controls
#controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem 20rem;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  transform: translateY(100%);
  transition: nth($transitions, 4);
  &.display {
    transform: translateY(0);
  }
}
.controls__cancel,
.controls__ok {
  padding: 1rem 2.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  transition: nth($transitions, 3);
  cursor: pointer;
  border-radius: 2rem;
}
.controls__cancel {
  border: none;
  box-shadow: $shadow-like-border;
  color: $main-color;
  &:hover {
    color: $h-color;
    box-shadow: $shadow-like-border;
  }
}
.controls__ok {
  display: flex;
  align-items: center;
  box-shadow: 0 0.06rem 0.28rem 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  background-image: linear-gradient(104deg, #57d0f2 0%, $main-blue 100%);
  font-weight: 600;
  background-size: 100%;
  &.with-icon {
    font-weight: 400;
  }
  &:hover {
    background-image: linear-gradient(104deg, #6facf7 0%, $main-blue 100%);
  }
  .icon {
    position: relative;
    left: 1.6rem;
    background: #fff;
    border-radius: 50%;
    padding: 0.4rem;
    color: $h-color;
    box-shadow: 0 0.06rem 0.28rem 0 rgba(0, 0, 0, 0.1);
  }
  &[disabled] {
    opacity: 0.25;
    cursor: not-allowed;
  }
}

#loader {
  .svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
#auto-login {
  min-height: 50rem;
  background-color: #fff;
}
@include mui-series {
}

.color-default {
  color: #aaa;
}
.color-secondary {
  color: $secondary-color;
}
.color-success {
  color: hsl(95, 46%, 53%);
}
.color-warning {
  color: #fbbd19;
}
.color-alert {
  color: rgba(230, 20, 10, 1);
}
.color-draft {
  color: #8d8d8d;
}
.color-primary {
  color: #18a0fb;
}

.bg-default {
  background-color: #aaa;
}
.bg-secondary {
  background-color: $secondary-color;
}
.bg-info {
  background-color: #6ca4ea;
}
.bg-success {
  background-color: #06d9b1;
}
.bg-primary {
  background-color: #18a0fb;
}
.bg-warning {
  background-color: #ffb06a;
}
.bg-alert {
  background-color: #ff636e;
}
.bg-draft {
  background-color: #e8eaed;
  color: #241c15 !important;
}

.border-draft {
  border-color: #e8eaed !important;
}

.border-alert {
  border-color: rgba(230, 20, 10, 1) !important;
}

.border-warning {
  border-color: #ffb06a !important;
}

.border-success {
  border-color: #62deb0 !important;
}

.border-success-opened {
  border-color: #2495e1 !important;
}

.border-info {
  border-color: #6ca4ea !important;
}

// Style integration  for React-paginate component & foundation
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
}
.search {
  display: flex;
  padding-left: 2rem;
  padding-bottom: 0.3rem;
  .search-input {
    padding-left: 0.4rem;
    border: 1px solid #ccc;
    border-right: hidden;
    width: 300px;
    outline: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .search-submit:focus {
    outline: none;
  }
  .search-submit {
    padding: 0.5rem 0.7rem;
    border: 1px solid #ccc;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.filter-select {
  border-radius: 3px;
}
.nothing-found {
  background-color: #f4f4f4;
  padding: 0.5rem;
  margin: 1.5rem auto;
  &__title {
    font-size: 0.9rem;
    color: #505050;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__table-cell {
    display: table-cell;
    vertical-align: top;
    padding: 5px;
  }
}

.centered-row {
  justify-content: center;
}

.icon-control-pause {
  color: $main-orange;
}
.icon-control-play {
  color: $success-color;
}
.icon-stop2,
.alert {
  color: $alert-color !important;
}
.icon-compass,
.icon-refresh {
  color: #505050;
  font-size: 26px !important;
}
.hide {
  display: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.ttp-button {
  margin-top: 2rem;
}

.param-label {
  font-size: 0.9rem;
  color: $gray-dark;
  font-weight: bold;
  margin: 0.5rem 0;
  text-transform: uppercase;
}
.obligatory {
  color: $alert-color;
  font-size: 1.5rem;
}
.modal {
  &-message {
    margin: 20px;
    font-size: 1.2rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
  &-group-btn {
    float: right;
    button {
      text-transform: uppercase;
      font-weight: 700;
      padding: 0.8rem 1rem;
      font-size: 0.8rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      border-radius: 2rem;
    }
  }
  &-close.close {
    right: 40px;
  }
}
.danger-button {
  background: $alert-color;
  &:hover {
    background: $delete-button-color;
  }
}
.field-dropdown {
  display: inline-block;
  font-weight: 500;
  margin: 0px;
  .menu__dropdown {
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1),
      0 0 0 1px rgba(44, 43, 63, 0.13);
    margin-top: 8px;
    border-radius: 0.2rem;
    border-top-right-radius: 0;
    opacity: 0;
    transition: nth($transitions, 2);
    padding-right: 1rem;
    background: #fff;
    z-index: 2;
  }
  li {
    margin: 0 0.5rem;
    transition: nth($transitions, 3);
    position: relative;
    top: 1px;
    cursor: pointer;
    .menu__link {
      .icon {
        margin-left: 5px;
      }
      &.active {
        border-bottom: 2px solid;
        color: #2c2b3f;
      }
    }
    &:hover .menu__link {
      color: #2c2b3f;
    }
  }
  .menu__sub-child {
    &:hover {
      font-weight: 500;
      color: $main-blue;
    }
    width: 7rem;
    padding: 3px 7px;
    display: flex;
    text-transform: uppercase;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &:hover .menu__dropdown {
    opacity: 1;
    visibility: visible;
  }
}
.border-danger {
  border: 1px solid #d10c0c;
  &::placeholder {
    color: #d10c0c;
  }
}
.bg-d8 {
  background-color: #d8d8d8;
}
.ttp-create-btn {
  border-radius: 2px;
  background-color: #5081be;
  padding: 0.8rem 1rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.page {
  padding-top: 25px;
  margin-bottom: 6rem;
  .not-found {
    padding: 0;
  }
  &__header {
    padding: 1rem 0;
    h3::first-letter {
      text-transform: capitalize;
    }
  }
}

#offCanvas-wrapper {
  display: flex;
  flex-direction: column;
}

.srv-content {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  min-height: 100vh;

  header,
  footer {
    flex: 0 1 auto;
  }
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  height: 0.4rem;
  border-radius: 0;
  background: rgba(109, 127, 146, 0.5);
  border-radius: 50px;

  .progress-bar-meter {
    position: relative;
    display: block;
    width: 0%;
    height: 100%;
    background-color: $curious-blue;
    border-radius: 50px;
    transition: 0.3s width cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  &-info {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $curious-blue;
  }
}

.fullscreen {
  width: 100%;
  background: #fafbfb;
} // Do not edit this style, it's used for the Component FullScreen
.fw {
  width: 100%;
}
.fh {
  height: 100%;
}
.fp {
  width: 100%;
  height: 100%;
}
.flex-1 {
  flex: 1;
}

.no-box-shadow {
  box-shadow: none !important;
}
.no-border {
  border: none !important;
}

textarea + .help {
  float: right;
  cursor: default;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: rgba(109, 127, 146, 0.8);
}

@media only screen and (max-width: 768px) {
  textarea + .help {
    display: none;
  }
}

.row {
  max-width: 82rem;
}

.ripple_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  &.fixed-loader {
    position: fixed;
    top: 18rem;
  }
}
