.stoolbar {
  background-color: #fefefe !important;
}

:global .toolbar-slider--medium {
  width: 385px;
  overflow-y: hidden;
}

.toolbar_content {
  overflow-y: auto;
}

.block_title {
  color: #29394c;
  font-size: 1rem;
  margin: 1rem;
}

.survey_themes {
  padding: 1rem;
  padding-bottom: 0;
  background: #f1f2f4;

  &__wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &__item {
    margin-right: 1rem;
    margin-bottom: 1rem;
    transition: 0.2s;
    position: relative;
    cursor: pointer;
    color: #262627;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

    &.selected {
      &::after {
        z-index: 2;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        opacity: 0;
        border-radius: 8px;
        box-shadow: inset 0 0 0 3px #4fb0ae;
        transition: 0.15s;
        opacity: 1;
      }
      &.with_check {
        &::after {
          background-color: #29394d1f;
        }
      }
    }
    .wrapper {
      height: 120px;
      border-radius: 8px 8px 0 0;
      padding: 24px;
      background-size: cover;
      background-position: 50% 50%;
      position: relative;
      z-index: 2;
      background-color: #fefefe;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4fb0ae;

      i {
        font-size: 20px;
      }
    }
    .title {
      flex-shrink: 0;
      min-width: 0;
      box-shadow: inset 0 1px rgba(0, 0, 0, 0.07);
      padding: 12px;
      background: #fefefe;
      border-radius: 0 0 8px 8px;
    }

    &:hover {
      box-shadow: none;
      transition: box-shadow 0.3s ease-in-out;
      box-shadow: 0 1px 1px rgba(254, 253, 253, 0.04);
    }
  }
}

.background_list {
  margin-bottom: 1rem;
  overflow: hidden;

  .background_show_icon {
    cursor: pointer;
  }

  .background_title_wrapper {
    padding: 0.5rem 1rem;
    background: #1e99ec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fafbfb;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0.5rem;
    border-radius: 0 0 4px 4px;
    z-index: 3;
    position: relative;
  }

  .background_content {
    margin: 0 1rem;
    border-radius: 0 0 4px 4px;
    padding-left: 1rem;
    background: #f1f2f4;
    transition: height 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
    padding-top: 1rem;

    .list {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
